import Head from "next/head";
import { useEffect, useMemo } from "react";
import { useEventTracker } from "utils/useEventTracker";
import { useRouter } from "next/router";
import { GetServerSideProps } from "next";
import client from "utils/client";
import { Redirect } from "elements/Redirect";

const PAGE_TITLE = "Save money on your property taxes";
const PAGE_DESCRIPTION =
	"Only pay if you save. Sign up in under 3 minutes. No upfront cost or hidden fees";

export const getServerSideProps: GetServerSideProps = async context => {
	try {
		const { data } = await client.getReferralProgramByCode({
			referralCode: context.query.owl as string,
		});

		const referralProgram = data.program;

		let metadataImageUrl: string;

		if (referralProgram?.toLowerCase() === "school_district") {
			metadataImageUrl = "/ownwell-referral-school-district.png";
		} else {
			metadataImageUrl = "/ownwell-referral-2.png";
		}

		return {
			props: {
				metadataImageUrl,
			},
		};
	} catch (err) {
		console.error(err);
		return {
			props: {
				metadataImageUrl: "/ownwell-referral-2.png",
			},
		};
	}
};

const ReferralLink = ({ metadataImageUrl }: { metadataImageUrl: string }) => {
	const router = useRouter();

	const trackReferralLinkPageVisit = useEventTracker();

	useEffect(() => {
		trackReferralLinkPageVisit({
			eventName: "",
			data: {
				"Visited From": document.referrer,
			},
		});
	}, [router.isReady]);

	return (
		<div>
			<Head>
				<title>{PAGE_TITLE}</title>
				<meta property="title" key="title" content={PAGE_TITLE} />
				<meta property="og:title" key="og:title" content={PAGE_TITLE} />
				<meta
					property="twitter:title"
					key="twitter:title"
					content={PAGE_TITLE}
				/>
				<meta name="description" key="description" content={PAGE_DESCRIPTION} />
				<meta
					property="og:description"
					key="og:description"
					content={PAGE_DESCRIPTION}
				/>
				<meta
					name="twitter:description"
					key="twitter:description"
					content={PAGE_DESCRIPTION}></meta>
				<meta property="og:image" content={metadataImageUrl} key="og:image" />
				<meta
					key="twitter:image"
					name="twitter:image"
					content={metadataImageUrl}
				/>
			</Head>
			{router.query.owl && <Redirect to={`/?owl=${router.query.owl}`} />}
		</div>
	);
};

export default ReferralLink;
