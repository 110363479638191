import { useRouter, NextRouter } from "next/router";
import { FC, useEffect } from "react";

interface RedirectProps {
	to: string;
	as?: string;
	options?: Parameters<NextRouter["push"]>[2];
}

export const Redirect: FC<RedirectProps> = props => {
	const { to, as, options } = props;

	const router = useRouter();

	useEffect(() => {
		router.push(to, as);
	}, [to, as, options]);

	return null;
};
